import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { ListToMap } from '../../../lib/lib-ngx/utils/ListToMap';
import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { SavEventModalComponent } from '../modals/sav-event-modal/sav-event-modal.component';
import { AddNotFoundOrderBagComponent } from '../modals/add-not-found-order-bag/add-not-found-order-bag.component';
import { ArticleFamilyType } from '../../../lib/lib-shared/types/ArticleFamilyType';

@Component({
  selector: 'lm-scan-bag',
  templateUrl: './scan-bag.component.html',
  styleUrls: ['./scan-bag.component.scss']
})
export class ScanBagComponent implements OnInit {
  public ArticleFamilyType = ArticleFamilyType;

  @ViewChild('bagIdInput') public bagIdInput: ElementRef;
  public bagList = [];
  public articleRangeMap = new Map();
  public selectedBag = null;
  public bagIdFormControl = new FormControl('');
  public orderIncident = null;

  constructor(
    private apiLavandierService: ApiLavandierService,
    private router: Router,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.initOrderBagRefList();
    this.loadArticleRangeMap();
  }

  initOrderBagRefList() {
    this.bagIdFormControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(
        ref => {
          if (ref.length) {
            this.apiLavandierService.getOrderBagRefList(ref)
              .subscribe(
                (data: any) => {
                  this.bagList = data.orderBagList;
                  this.orderIncident = data.incident;
                  const selectedBag = this.bagList.find(bag => bag.ref === ref);
                  this.selectedBag = selectedBag !== undefined ? selectedBag : null;
                  if (this.orderIncident !== null) {
                    this.bagIdInput.nativeElement.blur();
                    this.onOpenSavEventModal();
                  }
                }
              );
          } else {
            this.reset();
          }
        }
      );
  }

  /**
   * Method called to get the articleRange map
   */
  loadArticleRangeMap() {
    this.apiLavandierService.getArticleRangeList()
      .pipe(map(ListToMap.convert))
      .subscribe(
        (data: Map<number, Object>) => this.articleRangeMap = data
      );
  }

  reset() {
    this.bagList = [];
    this.selectedBag = null;
    this.orderIncident = null;
  }

  getFinishedBagNumber() {
    let num = 0;
    this.bagList.forEach((bag) => {
      if (bag.finishAt) {
        num++;
      }
    });
    return num;
  }

  onOpenSavEventModal() {
    const savEventModal = this.modalService.open(SavEventModalComponent, {size: 'lg'});
    savEventModal.componentInstance.incidentId = this.orderIncident.id;
  }

  notFoundRef() {
    const addNotFoundOrderBagComponent = this.modalService.open(AddNotFoundOrderBagComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });

    addNotFoundOrderBagComponent.result
      .then((ref: string) => {
        this.apiLavandierService.postOrderBagNotFound({ref: ref})
          .subscribe(() => this.reset());
      })
      .catch(() => this.reset());
  }
}
