import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DateFormatPipe, MomentModule } from 'ngx-moment';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { WebcamModule } from 'ngx-webcam';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ConsultOrderComponent } from './components/consult-order/consult-order.component';
import { HttpErrorInterceptorService } from '../lib/lib-ngx/services/http-error-interceptor.service';
import { GetValuesPipe } from './pipes/get-values.pipe';
import { LmUploadPipe } from './pipes/lm-upload.pipe';
import { NewBagModalComponent } from './components/modals/new-bag-modal/new-bag-modal.component';
import { ScanBagComponent } from './components/scan-bag/scan-bag.component';
import { SavEventModalComponent } from './components/modals/sav-event-modal/sav-event-modal.component';
import { LmImagePipe } from './pipes/lm-image.pipe';
import { ConfigService } from '../lib/lib-ngx/services/config.service';
import {
  InvoiceDeleteArticleModalComponent
} from './components/modals/invoice-delete-article-modal/invoice-delete-article-modal.component';
import { ValidateBagModalComponent } from './components/modals/validate-bag-modal/validate-bag-modal.component';
import { ReprintArticleTrackingComponent } from './components/modals/reprint-article-tracking/reprint-article-tracking.component';
import { StandByModalComponent } from './components/modals/stand-by-modal/stand-by-modal.component';
import { EnumToArrayPipe } from '../lib/lib-ngx/pipes/enum-to-array.pipe';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { AddNotFoundOrderBagComponent } from './components/modals/add-not-found-order-bag/add-not-found-order-bag.component';
import { HasFlagsPipe } from '../lib/lib-ngx/pipes/has-flags.pipe';
import { APP_TOKEN, appInitializerFn } from '../lib/lib-ngx/services/AppInitializerFn';
import { VersionService } from '../lib/lib-ngx/services/version.service';
import { HttpInterceptorVersionService } from '../lib/lib-ngx/services/http-interceptor-version.service';
import {
  OrderArticleTrackingDefectModalComponent
} from './components/modals/order-article-tracking-defect-modal/order-article-tracking-defect-modal.component';
import { WebcamModalComponent } from './components/modals/webcam-modal/webcam-modal.component';
import { ImagePreviewModalComponent } from './components/modals/image-preview-modal/image-preview-modal.component';

declare function require(moduleName: string): any;

// todo change when upgrading angular version https://stackoverflow.com/a/48869478
export const npm = require('../../package.json');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MenuComponent,
    InvoiceComponent,
    ConsultOrderComponent,
    GetValuesPipe,
    LmUploadPipe,
    NewBagModalComponent,
    ScanBagComponent,
    SavEventModalComponent,
    LmImagePipe,
    InvoiceDeleteArticleModalComponent,
    ValidateBagModalComponent,
    ReprintArticleTrackingComponent,
    StandByModalComponent,
    EnumToArrayPipe,
    ConfirmationModalComponent,
    AddNotFoundOrderBagComponent,
    HasFlagsPipe,
    OrderArticleTrackingDefectModalComponent,
    WebcamModalComponent,
    ImagePreviewModalComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxDatatableModule,
    NgHttpLoaderModule,
    MomentModule,
    ZXingScannerModule,
    WebcamModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true},
    DateFormatPipe,
    {provide: APP_TOKEN, useValue: {name: npm.name, version: npm.version}},
    {provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [ConfigService, VersionService, APP_TOKEN]},
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorVersionService, multi: true},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NewBagModalComponent,
    SavEventModalComponent,
    InvoiceDeleteArticleModalComponent,
    ValidateBagModalComponent,
    ReprintArticleTrackingComponent,
    StandByModalComponent,
    ConfirmationModalComponent,
    AddNotFoundOrderBagComponent,
    OrderArticleTrackingDefectModalComponent,
    WebcamModalComponent,
    ImagePreviewModalComponent,
  ]
})
export class AppModule {
}
