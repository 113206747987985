import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../lib/lib-ngx/utils/ListToMap';

@Component({
  selector: 'lm-consult-order',
  templateUrl: './consult-order.component.html',
  styleUrls: ['./consult-order.component.scss']
})
export class ConsultOrderComponent implements OnInit {
  public filterForm: FormGroup;
  public orderList = [];
  public shopMap = new Map();

  constructor(
    private apiLavandierService: ApiLavandierService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.resetData();
    this.loadData().subscribe();
  }

  buildForm() {
    this.filterForm = this.fb.group({
      orderId: '',
      estimatedDeliveryAt: ''
    });
  }

  resetData() {
    this.orderList = [];
    this.shopMap = new Map();
  }

  loadData(): Observable<any> {
    return forkJoin([
      this.apiLavandierService.getShops(),
      this.apiLavandierService.getShops(),
    ]).pipe(
      map(([orderList, shopList]) => {
        this.orderList = [ // todo must be removed
          {
            id: 1,
            user: {
              firstName: 'Marc',
              lastName: 'Hu'
            },
            estimatedDeliveryAt: '2019-02-01',
            shopId: 1
          },
          {
            id: 1,
            user: {
              firstName: 'Marc',
              lastName: 'Hu'
            },
            estimatedDeliveryAt: '2019-02-01',
            shopId: 1
          }
        ];
        this.shopMap = ListToMap.convert(shopList as any[]);
      })
    );
  }
}
