import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lm-add-not-found-order-bag',
  templateUrl: './add-not-found-order-bag.component.html',
  styleUrls: ['./add-not-found-order-bag.component.scss']
})
export class AddNotFoundOrderBagComponent implements OnInit {

  public orderBagModalForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.orderBagModalForm = this.fb.group({
      ref: ['', Validators.required],
    });
  }

  onValidate() {
    this.activeModal.close(this.orderBagModalForm.controls.ref.value);
  }
}
