import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { IOrderBag } from '../../../../lib/lib-ngx/web-services/api.service.type';
import { ArticleFamilyType } from '../../../../lib/lib-shared/types/ArticleFamilyType';

@Component({
  selector: 'lm-new-bag-modal',
  templateUrl: './new-bag-modal.component.html',
  styleUrls: ['./new-bag-modal.component.scss']
})
export class NewBagModalComponent implements OnInit {
  public ArticleFamilyType = ArticleFamilyType;

  public articleRangeList = [];
  public rangeSelected: any;
  public typeSelected: ArticleFamilyType;
  public rangeEditable: boolean;
  public showRef = false;
  public ref = '';

  constructor(
    public activeModal: NgbActiveModal,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.loadRangeList();
  }

  /**
   * Method called to get the ArticleRangeList
   */
  loadRangeList() {
    this.apiLavandierService.getArticleRangeList()
      .subscribe((data: Object[]) => this.articleRangeList = data);
  }

  /**
   * Method called to close and send the selected range
   */
  onValidateRange() {
    const orderBag: IOrderBag = {
      rangeId: this.rangeSelected.id,
      parentId: null,
      articleOptionIdList: [],
      type: this.typeSelected,
      comment: '',
      ref: this.ref !== '' ? this.ref : null,
    };
    this.activeModal.close(orderBag);
  }
}
