import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ConsultOrderComponent } from './components/consult-order/consult-order.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ScanBagComponent } from './components/scan-bag/scan-bag.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent},
  { path: 'consult-order', component: ConsultOrderComponent, canActivate: [AuthGuard]},
  { path: 'scan-bag', component: ScanBagComponent, canActivate: [AuthGuard]},
  { path: 'invoice/:orderBagId', component: InvoiceComponent, canActivate: [AuthGuard]},
  { path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
