import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IOrderBag } from '../../../../lib/lib-ngx/web-services/api.service.type';
import { ArticleFamilyType } from '../../../../lib/lib-shared/types/ArticleFamilyType';

@Component({
  selector: 'lm-validate-bag-modal',
  templateUrl: './validate-bag-modal.component.html',
  styleUrls: ['./validate-bag-modal.component.scss']
})
export class ValidateBagModalComponent implements OnInit {
  public articleRangeMap = new Map();
  public orderBag = null;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
  }

  onNewBag() {
    const orderBag: IOrderBag = {
      rangeId: this.orderBag.rangeId,
      parentId: this.orderBag.id,
      articleOptionIdList: [],
      type: this.orderBag.type === ArticleFamilyType.PIECE ? ArticleFamilyType.WEIGHT : ArticleFamilyType.PIECE,
      comment: this.orderBag.comment,
      ref: null,
    };
    this.activeModal.close(orderBag);
  }
}
